import React, { useEffect, useState, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import Peer from 'peerjs';
import { v4 as uuidv4 } from 'uuid';
import AudioManager from './AudioManager';

// Definir tipos para o state do player
export interface PlayerState {
  socketId: string;
  peerId: string;
  position: { x: number, y: number, z: number };
  rotation: { x: number, y: number, z: number };
  velocity?: { x: number, y: number, z: number };
  timestamp?: number;
}

interface MultiplayerManagerProps {
  onLocalPlayerMove: (position: PlayerState['position'], rotation: PlayerState['rotation']) => void;
  onPlayersUpdate: (players: PlayerState[]) => void;
}

// Interface para os métodos expostos pelo componente
export interface MultiplayerManagerRef {
  updatePosition: (position: PlayerState['position'], rotation: PlayerState['rotation']) => void;
  getLocalSocketId: () => string | undefined;
  getAllPlayers: () => PlayerState[];
  getLocalPosition: () => PlayerState['position'];
  getLocalRotation: () => PlayerState['rotation'];
}

const MultiplayerManager = React.forwardRef<MultiplayerManagerRef, MultiplayerManagerProps>(({ onLocalPlayerMove, onPlayersUpdate }, ref) => {
  const [players, setPlayers] = useState<Map<string, PlayerState>>(new Map());
  const socketRef = useRef<Socket | null>(null);
  const peerRef = useRef<Peer | null>(null);
  const localPlayerIdRef = useRef<string>(uuidv4());

  useEffect(() => {
    // Inicializar Socket.io
    const serverUrl = import.meta.env.VITE_SERVER_URL || 'http://localhost:3000';
    socketRef.current = io(serverUrl);

    // Inicializar PeerJS
    const peerOptions = {
      host: window.location.hostname,
      port: serverUrl.includes('localhost') ? 3000 : 443,
      path: '/peerjs',
      secure: window.location.protocol === 'https:'
    };

    peerRef.current = new Peer(localPlayerIdRef.current, peerOptions);

    // Posição inicial aleatória
    const initialPosition = {
      x: Math.random() * 40 - 20,
      y: 10,
      z: Math.random() * 40 - 20
    };

    const initialRotation = { x: 0, y: 0, z: 0 };

    // Quando conectar ao socket
    socketRef.current.on('connect', () => {
      console.log('Connected to server with socket ID:', socketRef.current?.id);

      // Registrar como jogador
      socketRef.current?.emit('player:join', {
        peerId: localPlayerIdRef.current,
        position: initialPosition,
        rotation: initialRotation
      });

      // Atualizar as referências locais
      localPositionRef.current = initialPosition;
      localRotationRef.current = initialRotation;

      // Notificar componente pai sobre posição inicial
      onLocalPlayerMove(initialPosition, initialRotation);
    });

    // Ouvir por novos jogadores
    socketRef.current.on('player:new', (newPlayer: PlayerState) => {
      console.log('New player joined:', newPlayer);
      updatePlayersList(newPlayer.socketId, newPlayer);
    });

    // Receber lista inicial de jogadores
    socketRef.current.on('players:list', (existingPlayers: PlayerState[]) => {
      console.log('Received existing players:', existingPlayers.length, existingPlayers);
      const newPlayers = new Map(players);

      // Processar cada jogador existente e garantir que tenha todas as propriedades necessárias
      existingPlayers.forEach(player => {
        // Garantir que a velocidade e timestamp existam
        const processedPlayer = {
          ...player,
          velocity: player.velocity || { x: 0, y: 0, z: 0 },
          timestamp: player.timestamp || Date.now()
        };

        newPlayers.set(player.socketId, processedPlayer);
        console.log(`Added existing player: ${player.socketId} at position:`, player.position);
      });

      setPlayers(newPlayers);

      // Forçar uma atualização imediata da lista de jogadores
      const playersList = Array.from(newPlayers.values());
      onPlayersUpdate(playersList);
    });

    // Atualizar posição de jogador
    socketRef.current.on('player:moved', (update: {
      socketId: string,
      position: PlayerState['position'],
      rotation: PlayerState['rotation'],
      velocity?: PlayerState['velocity'],
      timestamp?: number
    }) => {
      const player = players.get(update.socketId);
      const now = Date.now();
      const latency = update.timestamp ? now - update.timestamp : 0;

      // Usar velocidade do servidor ou criar uma padrão
      const serverVelocity = update.velocity || { x: 0, y: 0, z: 0 };

      if (player) {
        // Calcular velocidade com base na posição anterior e tempo decorrido
        const timeDelta = (update.timestamp || now) - (player.timestamp || now);
        const calculatedVelocity = {
          x: (update.position.x - player.position.x) / (Math.max(timeDelta, 10) / 1000),
          y: (update.position.y - player.position.y) / (Math.max(timeDelta, 10) / 1000),
          z: (update.position.z - player.position.z) / (Math.max(timeDelta, 10) / 1000)
        };

        // Aplicar um fator de suavização para a velocidade
        const smoothedVelocity = {
          x: serverVelocity.x * 0.8 + calculatedVelocity.x * 0.2,
          y: serverVelocity.y * 0.8 + calculatedVelocity.y * 0.2,
          z: serverVelocity.z * 0.8 + calculatedVelocity.z * 0.2
        };

        const updatedPlayer = {
          ...player,
          position: update.position,
          rotation: update.rotation,
          velocity: smoothedVelocity,
          timestamp: update.timestamp || now
        };

        // Atualização imediata para garantir fluidez
        updatePlayersList(update.socketId, updatedPlayer);

        // Limitamos os logs para evitar spam no console
        if (Math.random() < 0.02) {
          console.log(`Received update from player ${update.socketId}:`, update.position, `(latency: ${latency}ms)`);
        }
      } else {
        // Se o jogador não existe no nosso mapa, vamos criá-lo
        console.log(`Adding new player from update: ${update.socketId}`, update.position);

        // Criar novo jogador com os dados recebidos
        const newPlayer: PlayerState = {
          socketId: update.socketId,
          peerId: update.socketId, // Usando socketId como peerId para jogadores desconhecidos
          position: update.position,
          rotation: update.rotation,
          velocity: serverVelocity,
          timestamp: update.timestamp || now
        };

        // Adicionar ao mapa de jogadores e atualizar a lista
        updatePlayersList(update.socketId, newPlayer);
      }
    });

    // Remover jogador desconectado
    socketRef.current.on('player:left', (data: { socketId: string }) => {
      console.log('Player left:', data.socketId);
      const newPlayers = new Map(players);
      newPlayers.delete(data.socketId);
      setPlayers(newPlayers);
      onPlayersUpdate(Array.from(newPlayers.values()));
    });

    return () => {
      socketRef.current?.disconnect();
      peerRef.current?.destroy();
    };
  }, []);

  // Função auxiliar para atualizar lista de jogadores
  const updatePlayersList = (socketId: string, playerData: PlayerState) => {
    setPlayers(prevPlayers => {
      const newPlayers = new Map(prevPlayers);
      newPlayers.set(socketId, playerData);

      const playersArray = Array.from(newPlayers.values());
      onPlayersUpdate(playersArray);

      return newPlayers;
    });
  };

  // Estado para controle de frequência de envio
  const lastPositionUpdateRef = useRef<number>(0);
  const positionQueueRef = useRef<{position: PlayerState['position'], rotation: PlayerState['rotation']} | null>(null);

  // Função para enviar posição com throttling otimizado
  const sendPositionUpdate = (position: PlayerState['position'], rotation: PlayerState['rotation']) => {
    const now = Date.now();
    // Reduzimos o intervalo para 8ms = ~120fps para transmitir movimentos mais suaves
    const UPDATE_INTERVAL = 8;

    // Verificar se a posição mudou significativamente - reduza o limiar ainda mais
    const hasSignificantChange = positionQueueRef.current ? (
      Math.abs(position.x - positionQueueRef.current.position.x) > 0.001 ||
      Math.abs(position.y - positionQueueRef.current.position.y) > 0.001 ||
      Math.abs(position.z - positionQueueRef.current.position.z) > 0.001 ||
      Math.abs(rotation.x - positionQueueRef.current.rotation.x) > 0.001 ||
      Math.abs(rotation.y - positionQueueRef.current.rotation.y) > 0.001 ||
      Math.abs(rotation.z - positionQueueRef.current.rotation.z) > 0.001
    ) : true;

    // Calcular velocidade com base na última posição conhecida
    let velocity = { x: 0, y: 0, z: 0 };

    if (positionQueueRef.current) {
      const timeDelta = (now - lastPositionUpdateRef.current) / 1000; // em segundos
      if (timeDelta > 0) {
        velocity = {
          x: (position.x - positionQueueRef.current.position.x) / timeDelta,
          y: (position.y - positionQueueRef.current.position.y) / timeDelta,
          z: (position.z - positionQueueRef.current.position.z) / timeDelta
        };
      }
    }

    if (now - lastPositionUpdateRef.current > UPDATE_INTERVAL && hasSignificantChange) {
      // Enviar a atualização com a velocidade calculada
      socketRef.current?.emit('player:move', {
        position,
        rotation,
        velocity, // Incluir a velocidade calculada
        timestamp: now // Incluir timestamp para cálculos de latência
      });

      // Debugar ocasionalmente
      if (Math.random() < 0.02) {
        console.log('Sending position update:', position, 'velocity:', velocity);
      }

      lastPositionUpdateRef.current = now;
      positionQueueRef.current = { position, rotation };
    } else if (hasSignificantChange) {
      // Armazenamos a posição mais recente para enviar assim que possível
      positionQueueRef.current = { position, rotation };
    }
  };

  // Verificar periodicamente se temos posições na fila para enviar
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      if (positionQueueRef.current && now - lastPositionUpdateRef.current > 16) { // Reduzido para 16ms (60fps)
        // Limitamos os logs para evitar spam no console
        if (Math.random() < 0.05) {
          console.log('Sending queued position update:', positionQueueRef.current.position);
        }
        socketRef.current?.emit('player:move', {
          ...positionQueueRef.current,
          timestamp: now // Incluir timestamp para cálculos de latência
        });
        lastPositionUpdateRef.current = now;
        // Não limpamos a fila para poder comparar com a próxima posição
      }

      // A cada 1 segundo, forçamos um envio mesmo sem mudanças para garantir sincronização
      // (reduzido de 3 segundos para 1 segundo para melhor sincronização)
      if (now - lastPositionUpdateRef.current > 1000 && socketRef.current?.id) {
        const player = players.get(socketRef.current.id);
        if (player) {
          if (Math.random() < 0.1) { // Limitamos os logs
            console.log('Forcing position update for sync');
          }
          socketRef.current.emit('player:move', {
            position: player.position,
            rotation: player.rotation,
            timestamp: now
          });
          lastPositionUpdateRef.current = now;
        }
      }
    }, 16); // Verificamos a cada 16ms (60 vezes por segundo) para maior responsividade

    return () => clearInterval(interval);
  }, [players]);

  // Referências para a posição e rotação local
  const localPositionRef = useRef<PlayerState['position']>({ x: 0, y: 0, z: 0 });
  const localRotationRef = useRef<PlayerState['rotation']>({ x: 0, y: 0, z: 0 });

  // Funções expostas para o componente pai
  React.useImperativeHandle(ref, () => ({
    // Atualizar posição da nave
    updatePosition: (position: PlayerState['position'], rotation: PlayerState['rotation']) => {
      // Atualizar as referências locais
      localPositionRef.current = position;
      localRotationRef.current = rotation;
      sendPositionUpdate(position, rotation);
    },
    // Obter o ID do socket local
    getLocalSocketId: () => {
      return socketRef.current?.id;
    },
    // Obter todos os jogadores
    getAllPlayers: () => {
      return Array.from(players.values());
    },
    // Obter a posição local
    getLocalPosition: () => {
      return localPositionRef.current;
    },
    // Obter a rotação local
    getLocalRotation: () => {
      return localRotationRef.current;
    }
  }));

  // Este componente não renderiza nada no contexto 3D
  return null;
});

export default MultiplayerManager;
