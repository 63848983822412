import React, { useState, useEffect, useRef } from 'react';
import Peer from 'peerjs';
import { AudioProcessor, highQualityAudioConstraints } from '../utils/audioProcessor';

interface SimpleAudioControlsProps {
  localPeerId: string;
  remotePeerIds: string[];
  onSpeakingChange?: (peerId: string, isSpeaking: boolean) => void;
  localPosition?: { x: number, y: number, z: number };
  remotePositions?: Map<string, { x: number, y: number, z: number }>;
}

const SimpleAudioControls: React.FC<SimpleAudioControlsProps> = ({
  localPeerId,
  remotePeerIds,
  onSpeakingChange,
  localPosition = { x: 0, y: 0, z: 0 },
  remotePositions = new Map()
}) => {
  // Estados
  const [micEnabled, setMicEnabled] = useState<boolean>(false);
  const [audioEnabled, setAudioEnabled] = useState<boolean>(true);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);

  // Estados adicionais para detecção de atividade de voz
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const [speakingPeers, setSpeakingPeers] = useState<Map<string, boolean>>(new Map());

  // Referências
  const peerRef = useRef<Peer | null>(null);
  const localStreamRef = useRef<MediaStream | null>(null);
  const audioElementsRef = useRef<Map<string, HTMLAudioElement>>(new Map());
  const connectionsRef = useRef<Map<string, any>>(new Map());
  const audioProcessorRef = useRef<AudioProcessor | null>(null);
  const keepAliveIntervalRef = useRef<number | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const voiceDetectionIntervalRef = useRef<number | null>(null);
  const listenerRef = useRef<AudioListener | null>(null);
  const pannerNodesRef = useRef<Map<string, PannerNode>>(new Map());
  const audioSourcesRef = useRef<Map<string, MediaElementAudioSourceNode>>(new Map());

  // Constantes
  const MAX_CONNECTIONS = 10;
  const KEEP_ALIVE_INTERVAL = 30000;

  // Constantes para áudio espacial
  const AUDIO_REF_DISTANCE = 5; // Distância de referência para atenuação
  const AUDIO_MAX_DISTANCE = 50; // Distância máxima para atenuação
  const AUDIO_ROLLOFF_FACTOR = 1.5; // Fator de atenuação com a distância
  const AUDIO_CONE_INNER_ANGLE = 360; // Ângulo interno do cone de som
  const AUDIO_CONE_OUTER_ANGLE = 360; // Ângulo externo do cone de som
  const AUDIO_CONE_OUTER_GAIN = 0.8; // Ganho fora do cone de som

  // Função para adicionar logs
  const log = (message: string) => {
    console.log(`AudioControls: ${message}`);
  };

  // Função para inicializar o áudio espacial
  const initSpatialAudio = () => {
    try {
      // Criar contexto de áudio se não existir
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      // Criar listener se não existir
      if (!listenerRef.current) {
        listenerRef.current = audioContextRef.current.listener;

        // Definir posição inicial do listener
        if (listenerRef.current.positionX) {
          // API moderna
          listenerRef.current.positionX.value = localPosition.x;
          listenerRef.current.positionY.value = localPosition.y;
          listenerRef.current.positionZ.value = localPosition.z;

          // Orientação para frente (0, 0, -1) - "nariz" apontando para frente
          listenerRef.current.forwardX.value = 0;
          listenerRef.current.forwardY.value = 0;
          listenerRef.current.forwardZ.value = -1;

          // Orientação para cima (0, 1, 0) - "cabeça" apontando para cima
          listenerRef.current.upX.value = 0;
          listenerRef.current.upY.value = 1;
          listenerRef.current.upZ.value = 0;
        } else {
          // API legada
          listenerRef.current.setPosition(localPosition.x, localPosition.y, localPosition.z);
          listenerRef.current.setOrientation(0, 0, -1, 0, 1, 0); // Forward e Up
        }
      }

      log('Áudio espacial inicializado com sucesso');
      return true;
    } catch (err) {
      log(`Erro ao inicializar áudio espacial: ${err}`);
      return false;
    }
  };

  // Função para atualizar a posição do listener
  const updateListenerPosition = () => {
    if (!listenerRef.current || !audioContextRef.current) return;

    try {
      if (listenerRef.current.positionX) {
        // API moderna
        listenerRef.current.positionX.value = localPosition.x;
        listenerRef.current.positionY.value = localPosition.y;
        listenerRef.current.positionZ.value = localPosition.z;
      } else {
        // API legada
        listenerRef.current.setPosition(localPosition.x, localPosition.y, localPosition.z);
      }
    } catch (err) {
      log(`Erro ao atualizar posição do listener: ${err}`);
    }
  };

  // Função para atualizar a posição de um panner
  const updatePannerPosition = (peerId: string) => {
    if (!pannerNodesRef.current.has(peerId)) {
      // console.log(`Panner não encontrado para peer ${peerId}. Panners disponíveis:`, Array.from(pannerNodesRef.current.keys()));
      return;
    }

    if (!remotePositions.has(peerId)) {
      // console.log(`Posição não encontrada para peer ${peerId}. Posições disponíveis:`, Array.from(remotePositions.keys()));
      return;
    }

    try {
      const panner = pannerNodesRef.current.get(peerId)!;
      const position = remotePositions.get(peerId)!;

      // console.log(`Atualizando posição do panner para peer ${peerId}:`, position);

      if (panner.positionX) {
        // API moderna
        panner.positionX.value = position.x;
        panner.positionY.value = position.y;
        panner.positionZ.value = position.z;
      } else {
        // API legada
        panner.setPosition(position.x, position.y, position.z);
      }
    } catch (err) {
      log(`Erro ao atualizar posição do panner para ${peerId}: ${err}`);
    }
  };

  // Função para configurar a detecção de atividade de voz
  const setupVoiceActivityDetection = (stream: MediaStream) => {
    // Limpar detecção anterior, se existir
    if (voiceDetectionIntervalRef.current) {
      clearInterval(voiceDetectionIntervalRef.current);
      voiceDetectionIntervalRef.current = null;
    }

    try {
      // Criar contexto de áudio se não existir
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      // Criar analisador se não existir
      if (!analyserRef.current) {
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256;
        analyserRef.current.smoothingTimeConstant = 0.5;
      }

      // Conectar o stream ao analisador
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);

      // Configurar o intervalo para detecção de voz
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Limiar de volume para considerar como fala
      const VOICE_THRESHOLD = 25;

      // Contador para evitar falsos positivos/negativos
      let silenceCounter = 0;
      let speakingCounter = 0;

      // Iniciar intervalo para detecção
      voiceDetectionIntervalRef.current = window.setInterval(() => {
        if (!analyserRef.current || !micEnabled) return;

        // Obter dados de frequência
        analyserRef.current.getByteFrequencyData(dataArray);

        // Calcular volume médio
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }
        const average = sum / bufferLength;

        // Verificar se está falando
        const speaking = average > VOICE_THRESHOLD;

        // Adicionar histerese para evitar oscilações rápidas
        if (speaking) {
          silenceCounter = 0;
          speakingCounter++;

          // Considerar falando após 3 detecções consecutivas
          if (speakingCounter >= 3 && !isSpeaking) {
            setIsSpeaking(true);
            onSpeakingChange?.(localPeerId, true);
          }
        } else {
          speakingCounter = 0;
          silenceCounter++;

          // Considerar em silêncio após 10 detecções consecutivas
          if (silenceCounter >= 10 && isSpeaking) {
            setIsSpeaking(false);
            onSpeakingChange?.(localPeerId, false);
          }
        }
      }, 100); // Verificar a cada 100ms
    } catch (err) {
      log(`Erro ao configurar detecção de voz: ${err}`);
    }
  };

  // Função para detectar atividade de voz em streams remotos
  const setupRemoteVoiceDetection = (peerId: string, audioElement: HTMLAudioElement) => {
    try {
      // Criar contexto de áudio se não existir
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }

      // Criar analisador para este peer
      const analyser = audioContextRef.current.createAnalyser();
      analyser.fftSize = 256;
      analyser.smoothingTimeConstant = 0.5;

      // Inicializar áudio espacial se ainda não foi inicializado
      if (!listenerRef.current) {
        initSpatialAudio();
      }

      // Conectar o elemento de áudio ao analisador e ao panner para áudio espacial
      const source = audioContextRef.current.createMediaElementSource(audioElement);

      // Criar nó de panorama espacial
      const panner = audioContextRef.current.createPanner();
      panner.panningModel = 'HRTF'; // Modelo de alta qualidade
      panner.distanceModel = 'inverse'; // Modelo de atenuação inversa
      panner.refDistance = AUDIO_REF_DISTANCE;
      panner.maxDistance = AUDIO_MAX_DISTANCE;
      panner.rolloffFactor = AUDIO_ROLLOFF_FACTOR;
      panner.coneInnerAngle = AUDIO_CONE_INNER_ANGLE;
      panner.coneOuterAngle = AUDIO_CONE_OUTER_ANGLE;
      panner.coneOuterGain = AUDIO_CONE_OUTER_GAIN;

      // Definir posição inicial do panner
      if (remotePositions.has(peerId)) {
        const position = remotePositions.get(peerId)!;
        console.log(`Configurando posição inicial de áudio para peer ${peerId}:`, position);
        if (panner.positionX) {
          // API moderna
          panner.positionX.value = position.x;
          panner.positionY.value = position.y;
          panner.positionZ.value = position.z;
        } else {
          // API legada
          panner.setPosition(position.x, position.y, position.z);
        }
      } else {
        console.log(`Peer ${peerId} não tem posição definida. Posições disponíveis:`, Array.from(remotePositions.keys()));
      }

      // Conectar source -> panner -> analyser -> destination
      source.connect(panner);
      panner.connect(analyser);
      analyser.connect(audioContextRef.current.destination);

      // Armazenar referências para atualização posterior
      pannerNodesRef.current.set(peerId, panner);
      audioSourcesRef.current.set(peerId, source);

      // Configurar o intervalo para detecção de voz
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Limiar de volume para considerar como fala
      const VOICE_THRESHOLD = 25;

      // Contador para evitar falsos positivos/negativos
      let silenceCounter = 0;
      let speakingCounter = 0;
      let isPeerSpeaking = false;

      // Iniciar intervalo para detecção
      const detectionInterval = window.setInterval(() => {
        if (!analyser || !audioElement.srcObject) {
          clearInterval(detectionInterval);
          return;
        }

        // Obter dados de frequência
        analyser.getByteFrequencyData(dataArray);

        // Calcular volume médio
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i];
        }
        const average = sum / bufferLength;

        // Verificar se está falando
        const speaking = average > VOICE_THRESHOLD;

        // Adicionar histerese para evitar oscilações rápidas
        if (speaking) {
          silenceCounter = 0;
          speakingCounter++;

          // Considerar falando após 3 detecções consecutivas
          if (speakingCounter >= 3 && !isPeerSpeaking) {
            isPeerSpeaking = true;
            setSpeakingPeers(prev => {
              const newMap = new Map(prev);
              newMap.set(peerId, true);
              return newMap;
            });
            onSpeakingChange?.(peerId, true);
          }
        } else {
          speakingCounter = 0;
          silenceCounter++;

          // Considerar em silêncio após 10 detecções consecutivas
          if (silenceCounter >= 10 && isPeerSpeaking) {
            isPeerSpeaking = false;
            setSpeakingPeers(prev => {
              const newMap = new Map(prev);
              newMap.set(peerId, false);
              return newMap;
            });
            onSpeakingChange?.(peerId, false);
          }
        }
      }, 100); // Verificar a cada 100ms

      // Armazenar o intervalo para limpeza posterior
      return detectionInterval;
    } catch (err) {
      log(`Erro ao configurar detecção de voz para peer ${peerId}: ${err}`);
      return null;
    }
  };

  // Função para iniciar o mecanismo de keep-alive
  const startKeepAlive = () => {
    // Limpar intervalo anterior, se existir
    if (keepAliveIntervalRef.current !== null) {
      clearInterval(keepAliveIntervalRef.current);
      keepAliveIntervalRef.current = null;
    }

    // Iniciar novo intervalo
    keepAliveIntervalRef.current = window.setInterval(() => {
      // Verificar se há conexões ativas
      if (connectionsRef.current.size > 0) {
        // Verificar cada conexão
        connectionsRef.current.forEach((conn, peerId) => {
          // Verificar se a conexão está fechada ou em erro
          if (conn._negotiator && conn._negotiator.pc &&
              (conn._negotiator.pc.connectionState === 'failed' ||
               conn._negotiator.pc.connectionState === 'closed' ||
               conn._negotiator.pc.iceConnectionState === 'failed' ||
               conn._negotiator.pc.iceConnectionState === 'disconnected')) {

            log(`Keep-alive: Reconectando com ${peerId}`);

            // Fechar a conexão atual
            try {
              conn.close();
            } catch (err) {
              // Ignorar erros ao fechar
            }

            // Remover a conexão
            connectionsRef.current.delete(peerId);

            // Reconectar
            if (localStreamRef.current && micEnabled && peerRef.current) {
              try {
                const call = peerRef.current.call(peerId, localStreamRef.current);
                if (call) {
                  connectionsRef.current.set(peerId, call);
                  setupCallHandlers(call);
                }
              } catch (err) {
                // Ignorar erros ao reconectar
              }
            }
          }
        });
      }
    }, KEEP_ALIVE_INTERVAL);
  };

  // Função para parar o mecanismo de keep-alive
  const stopKeepAlive = () => {
    if (keepAliveIntervalRef.current !== null) {
      clearInterval(keepAliveIntervalRef.current);
      keepAliveIntervalRef.current = null;
    }
  };

  // Configurar handlers para uma chamada
  const setupCallHandlers = (call: any) => {
    // Processar o stream recebido
    call.on('stream', (remoteStream: MediaStream) => {
      // Verificar se o stream tem faixas de áudio
      if (remoteStream.getAudioTracks().length === 0) {
        return;
      }

      // Remover elemento de áudio existente, se houver
      const existingAudio = audioElementsRef.current.get(call.peer);
      if (existingAudio) {
        try {
          existingAudio.pause();
          existingAudio.srcObject = null;
          existingAudio.remove();
        } catch (err) {
          // Ignorar erros ao limpar
        }
      }

      // Criar elemento de áudio
      const audioElement = document.createElement('audio');
      audioElement.srcObject = remoteStream;
      audioElement.autoplay = true;
      audioElement.muted = !audioEnabled;

      // Adicionar ao DOM
      document.body.appendChild(audioElement);

      // Armazenar referência
      audioElementsRef.current.set(call.peer, audioElement);

      // Iniciar reprodução
      audioElement.play().then(() => {
        // Configurar detecção de atividade de voz para este peer
        setupRemoteVoiceDetection(call.peer, audioElement);
        console.log(`Áudio espacial configurado para peer ${call.peer}`);
      }).catch(err => {
        log(`Erro ao reproduzir áudio: ${err.message}`);
      });
    });
  };

  // Inicializar PeerJS assim que o componente for montado
  useEffect(() => {
    if (!localPeerId) return;

    console.log('Iniciando pré-inicialização do PeerJS...');

    // Limpar instância anterior, se existir
    if (peerRef.current) {
      try {
        peerRef.current.destroy();
      } catch (err) {
        // Ignorar erros ao destruir
      }
      peerRef.current = null;
    }

    // Criar instância do PeerJS
    try {
      const peerConfig = {
        config: {
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            {
              urls: 'turn:openrelay.metered.ca:80',
              username: 'openrelayproject',
              credential: 'openrelayproject'
            }
          ],
          iceCandidatePoolSize: 5
        },
        debug: 0
      };

      const peer = new Peer(localPeerId, peerConfig);
      peerRef.current = peer;

      // Configurar handlers
      peer.on('open', () => {
        // Iniciar o mecanismo de keep-alive
        startKeepAlive();
      });

      peer.on('call', (call) => {
        // Verificar se já temos muitas conexões
        if (connectionsRef.current.size >= MAX_CONNECTIONS) {
          // Limpar a conexão mais antiga
          const [oldestPeerId] = connectionsRef.current.keys();
          const oldConnection = connectionsRef.current.get(oldestPeerId);
          if (oldConnection) {
            try {
              oldConnection.close();
            } catch (err) {
              // Ignorar erros ao fechar
            }
            connectionsRef.current.delete(oldestPeerId);
          }
        }

        // Armazenar a conexão
        connectionsRef.current.set(call.peer, call);

        // Responder à chamada
        try {
          if (localStreamRef.current && micEnabled) {
            call.answer(localStreamRef.current);
          } else {
            call.answer();
          }
        } catch (err) {
          // Ignorar erros ao responder
          return;
        }

        // Configurar handlers para a chamada
        setupCallHandlers(call);
      });
    } catch (err) {
      // Ignorar erros ao criar instância
    }

    // Limpar recursos ao desmontar
    return () => {
      stopKeepAlive();

      // Parar o stream local
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => {
          try {
            track.stop();
          } catch (err) {
            // Ignorar erros ao parar
          }
        });
        localStreamRef.current = null;
      }

      // Limpar elementos de áudio
      audioElementsRef.current.forEach((audio) => {
        try {
          audio.pause();
          audio.srcObject = null;
          audio.remove();
        } catch (err) {
          // Ignorar erros ao limpar
        }
      });
      audioElementsRef.current.clear();

      // Limpar conexões
      connectionsRef.current.forEach((conn) => {
        try {
          conn.close();
        } catch (err) {
          // Ignorar erros ao fechar
        }
      });
      connectionsRef.current.clear();

      // Destruir o peer
      if (peerRef.current) {
        try {
          peerRef.current.destroy();
        } catch (err) {
          // Ignorar erros ao destruir
        }
        peerRef.current = null;
      }
    };
  }, [localPeerId]);

  // Chamar peers quando o microfone é ativado ou quando a lista de peers muda
  useEffect(() => {
    if (!micEnabled || !localStreamRef.current || !peerRef.current) return;

    // Chamar cada peer
    remotePeerIds.forEach((peerId) => {
      // Verificar se já existe uma conexão
      if (connectionsRef.current.has(peerId)) return;

      // Verificar se já temos muitas conexões
      if (connectionsRef.current.size >= MAX_CONNECTIONS) return;

      try {
        // Fazer a chamada
        const call = peerRef.current.call(peerId, localStreamRef.current);

        if (!call) return;

        // Armazenar a conexão
        connectionsRef.current.set(peerId, call);

        // Configurar handlers para a chamada
        setupCallHandlers(call);
      } catch (err) {
        // Ignorar erros ao chamar
      }
    });
  }, [micEnabled, remotePeerIds]);

  // Atualizar o estado de mudo dos elementos de áudio
  useEffect(() => {
    audioElementsRef.current.forEach((audio) => {
      audio.muted = !audioEnabled;
    });
  }, [audioEnabled]);

  // Atualizar posições do áudio espacial quando as posições mudarem
  useEffect(() => {
    // Atualizar posição do listener
    updateListenerPosition();

    // Atualizar posição de cada panner
    remotePositions.forEach((position, peerId) => {
      updatePannerPosition(peerId);
      console.log(`Atualizando posição de áudio para peer ${peerId}:`, position);
    });
  }, [localPosition, remotePositions]);

  // Função para ativar/desativar o microfone
  const handleMicrophoneToggle = () => {
    if (isInitializing) return;

    if (!micEnabled) {
      // Ativar microfone
      setIsInitializing(true);

      console.log('Iniciando acesso ao microfone...');

      // Pré-inicializar o processador de áudio para reduzir o tempo de inicialização
      if (!audioProcessorRef.current) {
        try {
          audioProcessorRef.current = new AudioProcessor();
          console.log('Processador de áudio pré-inicializado');
        } catch (err) {
          console.warn('Não foi possível pré-inicializar o processador de áudio:', err);
        }
      }

      navigator.mediaDevices.getUserMedia(highQualityAudioConstraints)
        .then(stream => {
          console.log('Microfone acessado com sucesso, aplicando processamento...');
          try {
            // Aplicar processamento de áudio para melhorar a qualidade
            if (!audioProcessorRef.current) {
              audioProcessorRef.current = new AudioProcessor();
            }
            const processedStream = audioProcessorRef.current.processStream(stream);
            localStreamRef.current = processedStream;

            // Configurar detecção de atividade de voz no stream processado
            setupVoiceActivityDetection(processedStream);
            console.log('Processamento de áudio aplicado com sucesso');
          } catch (err) {
            // Em caso de erro, usar o stream original
            console.error('Erro ao processar áudio:', err);
            localStreamRef.current = stream;

            // Configurar detecção de atividade de voz no stream original
            setupVoiceActivityDetection(stream);
          }

          setMicEnabled(true);
          setIsInitializing(false);
          console.log('Microfone inicializado e pronto para uso');
        })
        .catch(err => {
          console.error('Erro ao acessar o microfone:', err);
          setIsInitializing(false);
        });
    } else {
      // Desativar microfone

      // Limpar processador de áudio
      if (audioProcessorRef.current) {
        audioProcessorRef.current.dispose();
        audioProcessorRef.current = null;
      }

      // Limpar detecção de atividade de voz
      if (voiceDetectionIntervalRef.current) {
        clearInterval(voiceDetectionIntervalRef.current);
        voiceDetectionIntervalRef.current = null;
      }

      // Notificar que parou de falar
      if (isSpeaking) {
        setIsSpeaking(false);
        onSpeakingChange?.(localPeerId, false);
      }

      // Parar o stream local
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => {
          track.stop();
        });
        localStreamRef.current = null;
      }

      setMicEnabled(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      {/* Botão de microfone com animação de inicialização */}
      <button
        onClick={handleMicrophoneToggle}
        disabled={isInitializing}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: micEnabled ? '#4CAF50' : (isInitializing ? '#999' : '#333'),
          color: 'white',
          border: 'none',
          cursor: isInitializing ? 'wait' : 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px',
          position: 'relative',
          overflow: 'hidden'
        }}
        title={isInitializing ? "Inicializando microfone..." : (micEnabled ? "Desativar microfone" : "Ativar microfone")}
      >
        {isInitializing ? (
          <>
            <span style={{ opacity: 0.5 }}>🎤</span>
            <div style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '3px',
              backgroundColor: '#4CAF50',
              animation: 'progress 2s infinite linear'
            }} />
            <style>{`
              @keyframes progress {
                0% { width: 0; }
                100% { width: 100%; }
              }
            `}</style>
          </>
        ) : (micEnabled ? '🎤' : '🎤')}
      </button>

      {/* Botão de áudio */}
      <button
        onClick={() => setAudioEnabled(!audioEnabled)}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: audioEnabled ? '#4CAF50' : '#333',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20px'
        }}
        title={audioEnabled ? "Desativar áudio" : "Ativar áudio"}
      >
        {audioEnabled ? '🔊' : '🔇'}
      </button>
    </div>
  );
};

export default SimpleAudioControls;
