/**
 * Utilitário para processamento de áudio de alta qualidade
 * Implementa técnicas avançadas para melhorar a qualidade do áudio em transmissões P2P
 */

/**
 * Configurações para captura de áudio de alta qualidade
 */
export const highQualityAudioConstraints = {
  audio: {
    // Configurações gerais otimizadas para áudio limpo
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,

    // Configurações avançadas para melhor qualidade
    channelCount: 1, // Mono para melhor performance em transmissão de voz
    sampleRate: 44100, // Taxa de amostragem padrão para melhor compatibilidade
    sampleSize: 16, // Tamanho da amostra em bits

    // Configurações específicas para voz
    latency: 0.01, // Baixa latência para comunicação em tempo real

    // Configurações para redução de ruído (mais suaves)
    googEchoCancellation: true,
    googAutoGainControl: true,
    googNoiseSuppression: true,
    googHighpassFilter: true,
    googTypingNoiseDetection: true,
    googAudioMirroring: false
  },
  video: false
};

/**
 * Classe para processamento de áudio usando Web Audio API
 */
export class AudioProcessor {
  private context: AudioContext | null = null;
  private sourceNode: MediaStreamAudioSourceNode | null = null;
  private destinationNode: MediaStreamAudioDestinationNode | null = null;
  private gainNode: GainNode | null = null;
  private compressorNode: DynamicsCompressorNode | null = null;
  private analyserNode: AnalyserNode | null = null;
  private filterNode: BiquadFilterNode | null = null;
  private lowpassNode: BiquadFilterNode | null = null;
  private processedStream: MediaStream | null = null;
  private originalStream: MediaStream | null = null;
  private voiceActivityDetector: VoiceActivityDetector | null = null;
  private isProcessing: boolean = false;

  /**
   * Inicializa o processador de áudio
   */
  constructor() {
    try {
      this.context = new (window.AudioContext || (window as any).webkitAudioContext)({
        latencyHint: 'interactive', // Otimiza para interatividade em vez de qualidade máxima
        sampleRate: 44100 // Taxa de amostragem padrão para melhor compatibilidade
      });
      console.log('Contexto de áudio criado com sucesso');
    } catch (err) {
      console.error('Web Audio API não suportada:', err);
    }
  }

  /**
   * Processa um stream de áudio para melhorar a qualidade
   * @param stream Stream de áudio original
   * @returns Stream de áudio processado
   */
  public processStream(stream: MediaStream): MediaStream {
    if (!this.context) {
      console.warn('Web Audio API não disponível, retornando stream original');
      return stream;
    }

    try {
      this.originalStream = stream;

      // Criar nós de processamento
      this.sourceNode = this.context.createMediaStreamSource(stream);
      this.destinationNode = this.context.createMediaStreamDestination();

      // Nó de ganho para controle de volume
      this.gainNode = this.context.createGain();
      this.gainNode.gain.value = 0.95; // Ganho ligeiramente reduzido para evitar distorção

      // Compressor dinâmico para normalizar o volume e evitar picos
      this.compressorNode = this.context.createDynamicsCompressor();
      this.compressorNode.threshold.value = -20; // Limite em dB (menos agressivo)
      this.compressorNode.knee.value = 20; // Suavidade da compressão (mais suave)
      this.compressorNode.ratio.value = 6; // Taxa de compressão (menos agressiva)
      this.compressorNode.attack.value = 0.005; // Tempo de ataque em segundos (mais suave)
      this.compressorNode.release.value = 0.2; // Tempo de liberação em segundos

      // Analisador para detecção de atividade de voz (tamanho reduzido para inicialização mais rápida)
      this.analyserNode = this.context.createAnalyser();
      this.analyserNode.fftSize = 1024; // Reduzido de 2048 para inicialização mais rápida
      this.analyserNode.smoothingTimeConstant = 0.8;

      // Filtro passa-alta para remover ruídos de baixa frequência
      this.filterNode = this.context.createBiquadFilter();
      this.filterNode.type = 'highpass';
      this.filterNode.frequency.value = 75; // Frequência de corte em Hz (mais baixa para preservar mais da voz)
      this.filterNode.Q.value = 0.5; // Fator de qualidade (mais suave)

      // Filtro passa-baixa para suavizar agudos e tornar o áudio mais limpo
      this.lowpassNode = this.context.createBiquadFilter();
      this.lowpassNode.type = 'lowpass';
      this.lowpassNode.frequency.value = 8000; // Frequência de corte em Hz
      this.lowpassNode.Q.value = 0.5; // Fator de qualidade suave

      // Conectar os nós
      this.sourceNode.connect(this.filterNode); // Passa-alta
      this.filterNode.connect(this.lowpassNode); // Passa-baixa
      this.lowpassNode.connect(this.gainNode); // Ganho
      this.gainNode.connect(this.compressorNode); // Compressor
      this.compressorNode.connect(this.analyserNode); // Analisador
      this.analyserNode.connect(this.destinationNode); // Saída

      // Obter o stream processado
      this.processedStream = this.destinationNode.stream;

      // Iniciar detector de atividade de voz
      this.voiceActivityDetector = new VoiceActivityDetector(this.analyserNode, this.gainNode);
      this.voiceActivityDetector.start();

      this.isProcessing = true;

      return this.processedStream;
    } catch (err) {
      console.error('Erro ao processar stream de áudio:', err);
      return stream; // Retornar stream original em caso de erro
    }
  }

  /**
   * Ajusta o ganho do áudio
   * @param value Valor do ganho (0.0 a 2.0)
   */
  public setGain(value: number): void {
    if (this.gainNode && value >= 0 && value <= 2.0) {
      this.gainNode.gain.value = value;
    }
  }

  /**
   * Ativa ou desativa o processamento de áudio
   * @param enabled Estado do processamento
   */
  public setEnabled(enabled: boolean): MediaStream {
    if (enabled === this.isProcessing || !this.originalStream) {
      return this.processedStream || this.originalStream;
    }

    if (enabled) {
      return this.processStream(this.originalStream);
    } else {
      this.dispose();
      return this.originalStream;
    }
  }

  /**
   * Libera os recursos do processador de áudio
   */
  public dispose(): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.stop();
      this.voiceActivityDetector = null;
    }

    if (this.sourceNode) {
      this.sourceNode.disconnect();
      this.sourceNode = null;
    }

    if (this.filterNode) {
      this.filterNode.disconnect();
      this.filterNode = null;
    }

    if (this.lowpassNode) {
      this.lowpassNode.disconnect();
      this.lowpassNode = null;
    }

    if (this.gainNode) {
      this.gainNode.disconnect();
      this.gainNode = null;
    }

    if (this.compressorNode) {
      this.compressorNode.disconnect();
      this.compressorNode = null;
    }

    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.analyserNode = null;
    }

    if (this.destinationNode) {
      this.destinationNode.disconnect();
      this.destinationNode = null;
    }

    this.processedStream = null;
    this.isProcessing = false;
  }

  /**
   * Verifica se o processador está ativo
   */
  public isActive(): boolean {
    return this.isProcessing;
  }
}

/**
 * Classe para detecção de atividade de voz
 * Ajusta automaticamente o ganho com base na atividade de voz
 */
class VoiceActivityDetector {
  private analyser: AnalyserNode;
  private gainNode: GainNode;
  private dataArray: Uint8Array;
  private intervalId: number | null = null;
  private threshold: number = 30; // Limiar para detecção de voz (0-255)
  private noiseFloor: number = 20; // Nível de ruído de fundo
  private adaptiveGain: boolean = true; // Ajuste automático de ganho
  private silenceCounter: number = 0;
  private speakingCounter: number = 0;

  /**
   * Inicializa o detector de atividade de voz
   * @param analyser Nó analisador de áudio
   * @param gainNode Nó de ganho para ajuste automático
   */
  constructor(analyser: AnalyserNode, gainNode: GainNode) {
    this.analyser = analyser;
    this.gainNode = gainNode;
    this.dataArray = new Uint8Array(analyser.frequencyBinCount);
  }

  /**
   * Inicia a detecção de atividade de voz
   */
  public start(): void {
    if (this.intervalId !== null) return;

    this.intervalId = window.setInterval(() => {
      this.detectVoiceActivity();
    }, 100); // Verificar a cada 100ms
  }

  /**
   * Para a detecção de atividade de voz
   */
  public stop(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  /**
   * Detecta atividade de voz e ajusta o ganho se necessário
   */
  private detectVoiceActivity(): void {
    this.analyser.getByteFrequencyData(this.dataArray);

    // Calcular o nível médio de áudio
    let sum = 0;
    for (let i = 0; i < this.dataArray.length; i++) {
      sum += this.dataArray[i];
    }
    const average = sum / this.dataArray.length;

    // Detectar atividade de voz
    const isSpeaking = average > this.threshold;

    // Atualizar contadores
    if (isSpeaking) {
      this.silenceCounter = 0;
      this.speakingCounter++;

      // Ajustar ganho se o nível estiver muito alto
      if (this.adaptiveGain && average > 200 && this.gainNode.gain.value > 0.3) {
        this.gainNode.gain.value -= 0.05;
      }
    } else {
      this.speakingCounter = 0;
      this.silenceCounter++;

      // Ajustar o limiar de detecção com base no ruído de fundo
      if (this.silenceCounter > 30 && average < this.noiseFloor) {
        this.noiseFloor = Math.max(10, average);
        this.threshold = this.noiseFloor + 10;
      }

      // Restaurar ganho durante silêncio prolongado
      if (this.adaptiveGain && this.silenceCounter > 50 && this.gainNode.gain.value < 1.0) {
        this.gainNode.gain.value += 0.05;
      }
    }
  }

  /**
   * Define se o ajuste automático de ganho está ativado
   * @param enabled Estado do ajuste automático
   */
  public setAdaptiveGain(enabled: boolean): void {
    this.adaptiveGain = enabled;
  }
}

/**
 * Função para verificar se o navegador suporta as configurações avançadas de áudio
 * @returns Objeto com informações sobre o suporte
 */
export const checkAdvancedAudioSupport = async (): Promise<{
  supported: boolean;
  webAudioSupported: boolean;
  constraints: MediaTrackSupportedConstraints;
  message: string;
}> => {
  let supported = false;
  let webAudioSupported = false;
  let message = '';
  let constraints: MediaTrackSupportedConstraints = {};

  try {
    // Verificar suporte à Web Audio API
    if (window.AudioContext || (window as any).webkitAudioContext) {
      webAudioSupported = true;
    } else {
      message = 'Web Audio API não suportada';
    }

    // Verificar suporte às configurações de áudio
    constraints = navigator.mediaDevices.getSupportedConstraints();

    // Verificar se as configurações essenciais são suportadas
    const requiredConstraints = [
      'echoCancellation',
      'noiseSuppression',
      'autoGainControl'
    ];

    const supportedConstraints = requiredConstraints.filter(c => constraints[c]);

    if (supportedConstraints.length === requiredConstraints.length) {
      supported = true;
    } else {
      const unsupported = requiredConstraints.filter(c => !constraints[c]);
      message = `Configurações não suportadas: ${unsupported.join(', ')}`;
    }

    return {
      supported: supported && webAudioSupported,
      webAudioSupported,
      constraints,
      message
    };
  } catch (err) {
    return {
      supported: false,
      webAudioSupported,
      constraints,
      message: `Erro ao verificar suporte: ${err instanceof Error ? err.message : String(err)}`
    };
  }
};
